import React from "react"
import { Tag } from "@project/common"
import { t } from "i18next"

const EmploymentTypeBButton = () => (
  <Tag
    title={t("Employment Type B")}
    borderColor={"#4cb476"}
    color={"#4cb476"}
    defaultBg={"#f2fff7"}
  />
)

const EmploymentTypeAButton = () => (
  <Tag
    title={t("Employment Type A")}
    borderColor={"#cda001"}
    color={"#cda001"}
    defaultBg={"#fffdf3"}
  />
)

const EmploymentTransitionSupportButton = () => (
  <Tag
    title={t("Employment Transition Support")}
    color={"#0782c8"}
    defaultBg={"#eff9ff"}
  />
)

const ServiceType = (type: number) => {
  switch (type) {
    case 2:
      return <EmploymentTypeBButton />
    case 1:
      return <EmploymentTypeAButton />
    case 3:
      return <EmploymentTransitionSupportButton />
    default:
      return null
  }
}

export { ServiceType }
