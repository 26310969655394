import { theme } from "@project/common"
import { Table } from "antd"
import styled from "styled-components"
export const Container = styled.div`
  margin: 15px 0;
`

export const WorkStyleTable = styled(Table)`
  @media print {
    display: none;
  }
  max-width: 100%;
  overflow-x: auto;
  th,
  td {
    border-color: #000 !important;
    padding: 10px 8px !important;
  }
  tr > td:first-child {
    border-left: 1px solid #000;
  }
  tr:first-child > th {
    border-top: 1px solid #000;
  }
  .occupation-column {
    min-width: 150px;
    border-left: 1px solid #000;
  }
  .work-style-column {
    min-width: 120px;
  }
  .staff-name-column {
    min-width: 120px;
    color: ${theme.colors.link};
  }
  .month-total-column {
    min-width: 80px;
  }
  th.qualifications-column {
    min-width: 100px;
    white-space: pre-line;
  }
  .continuation-year-column {
    min-width: 50px;
  }
  th.week-header {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  th.each-date {
    color: ${theme.colors.link} !important;
    font-weight: normal !important;
    text-decoration: underline;
  }
  th.each-date-work-style {
    font-weight: normal !important;
  }
  th.each-date-day {
    font-weight: normal !important;
  }
  th.average-hours-column {
    min-width: 50px;
  }
  th.people-conversion-column {
    min-width: 60px;
  }
`

export const OccupationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`

export const OccupationTag = styled.div<{ background: string }>`
  background: ${(props) => props.background || theme.colors.error};
  padding: 5px;
  font-size: 12px;
  border-radius: 5px;
  color: ${theme.colors.white};
`

export const ShiftHoursInfo = styled.div`
  margin: 20px 0;
  display: flex;
  width: 100%;
  justify-content: flex-end;

  @media print {
    display: none;
  }
`

interface IDayAttendanceContrainerProps {
  isDisabled?: boolean
}

export const DayAttendanceContainer = styled.div<IDayAttendanceContrainerProps>`
  cursor: ${(props) => (props.isDisabled ? "auto" : "pointer")};
  .attendance-popup-container {
    display: none;
    position: absolute;
    cursor: auto;
    z-index: 99;
    top: 12px;
    left: 50%;
    width: 670px;
    transform: translate(-50%, -100%);
    &.show {
      display: block;
    }
    .close-modal {
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
    }
    &:after {
      content: "";
      border-top: 10px solid #d2d1d1;
      border-right: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: 10px solid transparent;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 100%);
    }
  }
`

export const DiagonalDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    to top left,
    transparent,
    transparent 49%,
    black 49%,
    black 51%,
    transparent 51%,
    transparent
  );
  @media print {
    top: 1px;
    height: 90%;
  }
`
