import { API } from "@project/common"

interface IStaffAttendanceMonthlyScheduleWorkStyle {
  facility: string | number
  year: string | number
  month: string | number
}

const BASE_ATTENDANCE_SCHEDULE_URL = "/staff-schedules"

export const getStaffAttendanceMonthlyScheduleWorkStyle = ({
  facility,
  year,
  month,
}: IStaffAttendanceMonthlyScheduleWorkStyle) =>
  API.get(
    `${BASE_ATTENDANCE_SCHEDULE_URL}/work-style?facility=${facility}&year=${year}&month=${month}`,
  )

export const quickUpdateStaffAttendanceSchedule = (payload) =>
  API.post(BASE_ATTENDANCE_SCHEDULE_URL, payload)
