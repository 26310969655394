import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import SignaturePad from "react-signature-canvas"
import { DEFAULT_DATE_SIGNATURE_PAD_IMAGE } from "../../constants"
import { Wrapper } from "./SignaturePad.style"
import { Button } from "@project/common"

type SignType = "date" | "name" | "both"
interface IProps {
  onSave: (url: string, dateSign?: string) => void
  isLoading?: boolean
  sign?: string
  signType?: SignType
  dateSign?: string
  isDateSignatureChecked?: boolean
}
export const SignatureCanvas: React.FC<IProps> = ({
  onSave,
  isLoading,
  sign,
  dateSign,
  signType,
  isDateSignatureChecked,
}) => {
  const { t } = useTranslation()
  const signCanvasRef = React.useRef<any>()
  const dateCanvasRef = React.useRef<any>()
  const [isDateSign, setIsDateSign] = useState(signType === "both")
  const [clearSign, setClearSign] = useState({
    dateSign: false,
    sign: false,
  })
  const saveSignature = async () => {
    const sign = signCanvasRef?.current
      ?.getTrimmedCanvas()
      .toDataURL("image/png")

    if (isDateSign) {
      const dateSignature = dateCanvasRef.current
        .getTrimmedCanvas()
        .toDataURL("image/png")

      onSave(
        clearSign?.sign ? "" : sign,
        clearSign?.dateSign ? "" : dateSignature,
      )
    } else {
      onSave(
        clearSign?.sign ? "" : sign,
        clearSign?.dateSign || !isDateSignatureChecked ? "" : dateSign,
      )
    }
    setIsDateSign(false)
  }
  const clearSignature = () => {
    if (signCanvasRef) {
      signCanvasRef?.current?.clear()
    }
    dateCanvasRef?.current?.clear()
    dateCanvasRef?.current?.fromDataURL(DEFAULT_DATE_SIGNATURE_PAD_IMAGE, {
      width: 380,
      height: 80,
    })
    setClearSign({
      dateSign: true,
      sign: true,
    })
    setIsDateSign(false)
  }
  const clearSignSignature = () => {
    if (signCanvasRef) {
      signCanvasRef?.current?.clear()
    }
    setClearSign({
      ...clearSign,
      sign: true,
    })
  }
  const clearDateSignature = () => {
    setClearSign({
      ...clearSign,
      dateSign: true,
    })
    dateCanvasRef?.current?.clear()
    dateCanvasRef?.current?.fromDataURL(DEFAULT_DATE_SIGNATURE_PAD_IMAGE, {
      width: 380,
      height: 80,
    })
  }
  useEffect(() => {
    if (signCanvasRef?.current != undefined) {
      signCanvasRef?.current.fromDataURL(sign, { width: 480, height: 250 })
    }
    dateCanvasRef?.current?.fromDataURL(
      dateSign || DEFAULT_DATE_SIGNATURE_PAD_IMAGE,
      {
        width: 380,
        height: 80,
      },
    )
  }, [sign, dateSign])
  const renderPad = (type: SignType) => {
    return (
      <div>
        {/* Date signature canvas */}
        {type === "both" && (
          <div className={"date__signature_pad_container"}>
            <SignaturePad
              ref={dateCanvasRef}
              canvasProps={{
                width: 380,
                height: 80,
                className: "sigCanvas",
              }}
              onEnd={() => {
                setIsDateSign(true)
                setClearSign({
                  ...clearSign,
                  dateSign: false,
                })
              }}
            />

            <Button
              onClick={clearDateSignature}
              type={"default"}
              danger
              shape={"round"}
              btnText={t("Clear")}
            />
          </div>
        )}

        {/* Only signature canvas */}
        <div className={"pad"}>
          <SignaturePad
            ref={signCanvasRef}
            canvasProps={{
              width: 480,
              height: 250,
              className: "sigCanvas",
            }}
            onEnd={() => {
              setClearSign({
                ...clearSign,
                sign: false,
              })
            }}
          />
          <span
            style={{
              pointerEvents: "none",
            }}
          >
            {t("Sign")}
          </span>
          <Button
            onClick={clearSignSignature}
            type={"default"}
            danger
            shape={"round"}
            btnText={t("Clear")}
            width={"50px"}
            height={"20px"}
            style={{ wordBreak: "keep-all" }}
          />
        </div>
      </div>
    )
    switch (type) {
      case "name":
        return (
          <div className={"pad"}>
            <SignaturePad
              ref={signCanvasRef}
              canvasProps={{
                width: 480,
                height: 250,
                className: "sigCanvas",
              }}
              onEnd={() => {
                setClearSign({
                  ...clearSign,
                  sign: false,
                })
              }}
            />
            <span
              style={{
                pointerEvents: "none",
              }}
            >
              {t("Sign")}
            </span>
            <Button
              onClick={clearSignSignature}
              type={"default"}
              danger
              shape={"round"}
              btnText={t("Clear")}
              width={"50px"}
              height={"20px"}
            />
          </div>
        )
      case "both":
        return (
          <div className={"date__signature_pad_container"}>
            <SignaturePad
              ref={dateCanvasRef}
              canvasProps={{
                width: 380,
                height: 80,
                className: "sigCanvas",
              }}
              onEnd={() => {
                setIsDateSign(true)
                setClearSign({
                  ...clearSign,
                  dateSign: false,
                })
              }}
            />

            <Button
              onClick={clearDateSignature}
              type={"default"}
              danger
              shape={"round"}
              btnText={t("Clear")}
            />
          </div>
        )
      default:
        return null
    }
  }

  return (
    <Wrapper signType={signType}>
      <div className={"pad-container"}>{renderPad(signType)}</div>
      <div className={"btns-container"}>
        <Button
          onClick={clearSignature}
          type={"default"}
          danger
          shape={"round"}
          disabled={isLoading}
          btnText={t("Clear all")}
        />
        <Button
          onClick={saveSignature}
          isLoading={isLoading}
          shape={"round"}
          btnText={t("Save")}
        />
      </div>
    </Wrapper>
  )
}
