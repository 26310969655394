import {
  Button,
  CALENDAR_HOURS_MINUTES,
  STAFF_ABSENT,
  STAFF_PRESENT,
  SelectInput,
  USER_ATTENDANCE_VALUES,
} from "@project/common"
import { Checkbox, Input, InputNumber } from "antd"
import { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { styled } from "styled-components"
import { AttendanceTypeButtons } from "../AttendanceTypeButtons"

const Wrapper = styled.div`
  padding: 8px;
  border-radius: 12px;
  background: #f5f5f5;
  border: 1px solid #d2d1d1;

  .break-time-container {
    margin: 10px 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 4px;
    input {
      max-width: 75px;
    }

    .ant-checkbox-input {
      height: 40px;
    }

    .break-input-container {
      display: flex;
      align-items: center;
      gap: 4px;
      .hrs-content {
        min-width: 40px;
      }
    }
  }

  .inner-record-container {
    display: flex;
    align-items: center;
    gap: 5px;
    flex-wrap: wrap;
    div {
      padding-top: 0;
      padding-bottom: 0;
    }
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  .button-container {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
  }

  .save-reset-buttons {
    display: flex;
    justify-content: flex-end;
    .reset-button {
      border: none;
      background: transparent;
      padding-left: 10px;
      text-decoration: underline;
    }
  }
`

const AbsentWrapper = styled.div`
  color: #e40102;
  text-align: center;
  padding: 0 10px;
`

const AttendanceScheduleTimeRangeInputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  div {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .ant-input-number {
    max-width: 60px;
  }
`

export interface IAttendanceInfo {
  rest_minits_flg: boolean
  rest_hours: number | null
  attendance_type: number | null
  attendance_start_time: string
  attendance_end_time: string
  attendance_rest_minits: number | null
  attendance_type2: number | null
  attendance_start_time2: string
  attendance_end_time2: string
  attendance_rest_minits2: number | null
}

interface IStaffAttendanceRecordUpdatePopup {
  attendanceInfo: IAttendanceInfo
  handleAttendanceUpdate: (val) => void
}

interface IRecordButton {
  type: "remove" | "add"
}

type AttendanceCalendarHours = {
  id: number
  attendance_type: number
  start_hour: string
  start_minute: string
  end_hour: string
  end_minute: string
  break_time: number
}

export const StaffAttendanceRecordUpdatePopup = (
  props: IStaffAttendanceRecordUpdatePopup,
) => {
  const { t } = useTranslation()
  const { attendanceInfo, handleAttendanceUpdate } = props
  const possibleRecordIds = [1, 2]

  const initialValues = {
    attendance_type: 0,
    start_hour: null,
    start_minute: null,
    end_hour: null,
    end_minute: null,
    break_time: null,
  }

  const getHours = () => {
    if (!attendanceInfo?.attendance_type)
      return [
        {
          id: 1,
          ...initialValues,
        },
      ]
    const workHours = []

    possibleRecordIds.forEach((id) => {
      const postfix = id > 1 ? id : ""

      const attendanceTypeKey = "attendance_type" + postfix
      const ATTENDANCE_TYPE = attendanceInfo?.[attendanceTypeKey]

      const startTimeKey = "attendance_start_time" + postfix
      const startTimes = attendanceInfo?.[startTimeKey]?.split(":")
      const START_HOUR = startTimes ? startTimes[0] : null
      const START_MINUTE = startTimes ? startTimes[1] : null

      const endTimeKey = "attendance_end_time" + postfix
      const endTimes = attendanceInfo?.[endTimeKey]?.split(":")
      const END_HOUR = endTimes ? endTimes[0] : null
      const END_MINUTE = endTimes ? endTimes[1] : null

      const breakTimeKey = "attendance_rest_minits" + postfix
      const BREAK_TIME = attendanceInfo?.[breakTimeKey] ?? null

      if (id == 1 || ATTENDANCE_TYPE) {
        workHours.push({
          id,
          attendance_type: ATTENDANCE_TYPE ?? 0,
          start_hour: START_HOUR ?? null,
          start_minute: START_MINUTE ?? null,
          end_hour: END_HOUR ?? null,
          end_minute: END_MINUTE ?? null,
          break_time: BREAK_TIME ?? null,
        })
      }
    })
    return workHours
  }

  const [workHours, setWorkHours] =
    useState<Array<AttendanceCalendarHours>>(getHours())
  const [breakTime, setBreakTime] = useState({
    rest_minits_flg: attendanceInfo?.rest_minits_flg,
    rest_hours: attendanceInfo?.rest_hours,
  })

  const inValidData = useMemo(() => {
    for (let i = 0; i < workHours.length; i++) {
      if (
        workHours[i].attendance_type.toString() ==
          USER_ATTENDANCE_VALUES.ATTENDANCE &&
        (!workHours[i].start_hour ||
          !workHours[i].start_minute ||
          !workHours[i].end_hour ||
          !workHours[i].end_minute)
      ) {
        return true
      }
    }
    return false
  }, [workHours])

  const handleWorkHourChange = (id, key, value, extra?: any) => {
    const newState = workHours?.map((work) => {
      if (work.id === id) {
        work[key] = value
      }
      if (work.id === id && extra) {
        work[extra.key] = extra.value
      }
      return work
    })

    setWorkHours(newState)
  }

  const handleReset = () => setWorkHours([{ id: 1, ...initialValues }])
  const renderAttendanceRecordRange = ({ workHour, disabled = false }) => {
    return (
      <AttendanceScheduleTimeRangeInputContainer>
        {/* <div className={"title"}>{title}</div> */}
        <div className={"start-range"}>
          <SelectInput
            name={"attendance_start_hour"}
            width={"65px"}
            options={CALENDAR_HOURS_MINUTES.hours}
            value={workHour?.start_hour}
            disabled={disabled}
            onChange={(val) => {
              handleWorkHourChange(workHour.id, "start_hour", val, {
                key: "start_minute",
                value: workHour?.start_minute || "00",
              })
            }}
          />
          {":"}
          <SelectInput
            name={"attendance_start_minute"}
            width={"65px"}
            options={CALENDAR_HOURS_MINUTES.minutes}
            value={workHour?.start_minute}
            disabled={disabled}
            onChange={(val) =>
              handleWorkHourChange(workHour.id, "start_minute", val)
            }
          />
        </div>
        {"~"}
        <div className={"end-range"}>
          <SelectInput
            name={"attendance_end_hour"}
            width={"65px"}
            options={CALENDAR_HOURS_MINUTES.hours}
            value={workHour?.end_hour}
            disabled={disabled}
            onChange={(val) =>
              handleWorkHourChange(workHour.id, "end_hour", val, {
                key: "end_minute",
                value: workHour?.end_minute || "00",
              })
            }
          />
          {":"}
          <SelectInput
            name={"attendance_end_minute"}
            width={"65px"}
            options={CALENDAR_HOURS_MINUTES.minutes || []}
            value={workHour?.end_minute}
            disabled={disabled}
            onChange={(val) =>
              handleWorkHourChange(workHour.id, "end_minute", val)
            }
          />
        </div>
        <div className={"break-hours"}>
          {t("Break time")}
          <InputNumber
            value={workHour?.break_time}
            disabled={disabled}
            onChange={(val) => {
              handleWorkHourChange(workHour.id, "break_time", val)
            }}
            max={999}
            min={0}
          />
          {t("minutes")}
        </div>
      </AttendanceScheduleTimeRangeInputContainer>
    )
  }

  const addNewRecord = () => {
    setWorkHours([
      ...workHours,
      { id: workHours?.length + 1, ...initialValues },
    ])
  }
  const removeLastRecord = () => {
    const data = workHours?.splice(-1)[0]
    setWorkHours(workHours?.filter((v) => v.id !== data.id))
  }

  const RecordButton = ({ type }: IRecordButton) => {
    const { t } = useTranslation()
    return (
      <div className={"record-button"}>
        <Button
          btnText={
            type == "add" ? t("Add working hours") : t("Remove working hours")
          }
          shape={"round"}
          icon={type == "add" ? "add" : "close"}
          onClick={() => {
            type == "add" ? addNewRecord() : removeLastRecord()
          }}
        />
      </div>
    )
  }

  const handleSave = () => {
    const payload: any = {
      rest_minits_flg: breakTime.rest_minits_flg,
      rest_hours: breakTime.rest_hours,
    }
    workHours.forEach((work) => {
      const postFix = work?.id == 1 ? "" : work?.id
      payload[`attendance_type${postFix}`] = work?.attendance_type
      payload[`attendance_start_time${postFix}`] =
        work?.attendance_type == STAFF_PRESENT
          ? work.start_hour + ":" + work.start_minute
          : ""
      payload[`attendance_end_time${postFix}`] =
        work?.attendance_type == STAFF_PRESENT
          ? work.end_hour + ":" + work.end_minute
          : ""
      payload[`attendance_rest_minits${postFix}`] = work.break_time
    })

    if (workHours.length == 1) {
      payload[`attendance_type2`] = 0
      payload[`attendance_start_time2}`] = ""
      payload[`attendance_end_time2`] = ""
      payload[`attendance_rest_minits2`] = null
    }

    handleAttendanceUpdate(payload)
  }

  return (
    <Wrapper>
      <div className={"break-time-container"}>
        <Checkbox
          name={"break-time"}
          checked={breakTime.rest_minits_flg}
          onChange={() =>
            setBreakTime(() => ({
              rest_minits_flg: !breakTime.rest_minits_flg,
              rest_hours: 0,
            }))
          }
        />
        <span>&nbsp;{t("Break time")}</span>
        <div className={"break-input-container"}>
          <Input
            type={"number"}
            min={0}
            max={999}
            width={10}
            placeholder={t("Eg: 8")}
            disabled={!breakTime.rest_minits_flg}
            onChange={(e) =>
              setBreakTime((prev) => ({
                ...prev,
                rest_hours: +e?.target?.value,
              }))
            }
            value={breakTime.rest_hours}
          />
          <span className={"hrs-content"}>{t("Hrs")}</span>
        </div>
      </div>
      <div className={"record-container"}>
        {workHours?.map((range, index) => (
          <div key={index} className={"inner-record-container"}>
            <div>{`${t("Working hours")} ${range?.id}`}</div>
            {range.attendance_type == STAFF_PRESENT ? (
              renderAttendanceRecordRange({
                workHour: range,
              })
            ) : range.attendance_type == STAFF_ABSENT ? (
              <AbsentWrapper>{t("Absence")}</AbsentWrapper>
            ) : (
              <AttendanceTypeButtons
                onChange={(val) => {
                  handleWorkHourChange(range?.id, "attendance_type", val)
                }}
              />
            )}
          </div>
        ))}
        <div className={"button-container"}>
          {workHours?.length > 0 &&
          workHours[0].attendance_type == STAFF_PRESENT ? (
            <RecordButton
              type={
                workHours.length == possibleRecordIds.length ? "remove" : "add"
              }
            />
          ) : (
            <>&nbsp;</>
          )}
          <div className={"save-reset-buttons"}>
            <Button
              btnText={t("Save")}
              color={"#ffffff"}
              shape={"round"}
              onClick={handleSave}
              disabled={inValidData}
            />
            {(workHours?.length > 1 || workHours?.[0]?.attendance_type > 0) && (
              <Button
                btnText={t("Reset")}
                className={"reset-button"}
                onClick={handleReset}
              />
            )}
          </div>
        </div>
      </div>
    </Wrapper>
  )
}
