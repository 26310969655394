import {
  Button,
  CALENDAR_HOURS_MINUTES,
  SelectInput,
  theme,
} from "@project/common"
import { InputNumber } from "antd"
import { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { styled } from "styled-components"

const Wrapper = styled.div`
  padding: 8px;
  border-radius: 12px;
  background: #f5f5f5;
  border: 1px solid #d2d1d1;
  .shift-select {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
  }
  .schedule-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
  }
  .inner-schedule-container {
    .content {
      display: flex;
      align-items: center;
      gap: 10px;
      .ant-typography {
        margin-bottom: 0;
      }
      .inputs {
        display: flex;
        flex-direction: row;
        .ant-select {
          width: 65px;
        }
      }
    }
  }
  .button-container {
    margin-top: 5px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .schedule-button {
      button {
        background: ${theme.colors.white};
        border-color: ${theme.colors.background};
      }
      .remove {
        color: ${theme.colors.error};
        &:hover {
          border-color: ${theme.colors.error};
        }
      }
    }
  }
`

const AttendanceScheduleTimeRangeInputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  div {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .ant-input-number {
    max-width: 60px;
  }
`

interface IStaffAttendanceScheduleUpdatePopup {
  shiftOptions?: Array<any>
  shiftInfo?: IShiftInfo
  handleAttendanceScheduleUpdate?: (val) => void
}

export interface IShiftInfo {
  attendance_start_time?: string
  attendance_end_time?: string
  attendance_rest_minits?: number
  attendance_start_time2?: string
  attendance_end_time2?: string
  attendance_rest_minits2?: number
  attendance_shift_id?: number
}

export type CalendarHours = {
  id: number
  start_hour: string
  start_minute: string
  end_hour: string
  end_minute: string
  break_time: number
}

interface IScheduleButton {
  type: "remove" | "add"
  disabled?: boolean
}

export const StaffAttendanceScheduleUpdatePopup = ({
  shiftOptions = [],
  shiftInfo,
  handleAttendanceScheduleUpdate,
}: IStaffAttendanceScheduleUpdatePopup) => {
  const { t } = useTranslation()
  const resetShiftOption = {
    label: "--",
    value: 0,
  }

  const possibleScheduleIds = [1, 2]

  const initialValues = {
    start_hour: null,
    start_minute: null,
    end_hour: null,
    end_minute: null,
    break_time: null,
  }

  const getHours = (
    shiftId = shiftInfo.attendance_shift_id,
    setHoursFromShift = false,
  ) => {
    const ids = possibleScheduleIds
    const shiftHours = []
    if (!shiftId)
      return [
        {
          id: 1,
          ...initialValues,
        },
      ]
    const selectedShift = setHoursFromShift
      ? shiftOptions?.find((shift) => shift?.id == shiftId)
      : shiftInfo
    for (let i = 1; i <= ids.length; i++) {
      const postfixKey = i == 1 ? "" : setHoursFromShift ? "_" + i : i
      const startTimeKey = "attendance_start_time" + postfixKey
      const startTimes = selectedShift[startTimeKey]?.split(":")
      const START_HOUR = startTimes ? startTimes[0] : null
      const START_MINUTE = startTimes ? startTimes[1] : null

      const endTimeKey = "attendance_end_time" + postfixKey
      const endTimes = selectedShift[endTimeKey]?.split(":")
      const END_HOUR = endTimes ? endTimes[0] : null
      const END_MINUTE = endTimes ? endTimes[1] : null

      const breakTimeKey = "attendance_rest_minits" + postfixKey
      const BREAK_TIME = selectedShift[breakTimeKey] ?? null
      if (
        i === 1 ||
        START_HOUR ||
        START_MINUTE ||
        END_HOUR ||
        END_MINUTE ||
        BREAK_TIME
      )
        shiftHours.push({
          id: i,
          start_hour: START_HOUR ?? null,
          start_minute: START_MINUTE ?? null,
          end_hour: END_HOUR ?? null,
          end_minute: END_MINUTE ?? null,
          break_time: BREAK_TIME ?? null,
        })
    }
    return shiftHours
  }

  const shiftOptionsWithResetValue = [resetShiftOption].concat(shiftOptions)

  const [shiftHours, setShiftHours] = useState<Array<CalendarHours>>(getHours())
  const [selectedAttendanceShift, setSelectedAttendanceShift] = useState(
    shiftInfo.attendance_shift_id,
  )

  const isSelectedShiftVacationShift = useMemo(() => {
    return shiftOptions?.find((shift) => shift?.id == selectedAttendanceShift)
      ?.number_of_employees_include_flg
      ? true
      : false
  }, [selectedAttendanceShift])

  const addNewSchedule = () => {
    setShiftHours([
      ...shiftHours,
      { id: shiftHours?.length + 1, ...initialValues },
    ])
  }
  const removeLastSchedule = () => {
    const data = shiftHours?.splice(-1)[0]
    setShiftHours(shiftHours?.filter((v) => v.id !== data.id))
  }

  const ScheduleButton = ({ type, disabled = false }: IScheduleButton) => {
    const { t } = useTranslation()
    return (
      <div className={"schedule-button"}>
        <Button
          shape={"round"}
          icon={type == "add" ? "add" : "close"}
          onClick={() => {
            type == "add" ? addNewSchedule() : removeLastSchedule()
          }}
          btnText={
            type == "add" ? t("Add working hours") : t("Remove working hours")
          }
          className={type}
          disabled={disabled}
        />
      </div>
    )
  }

  const updateShiftHoursOnShiftChange = (shiftId) => {
    const shiftBasedHours = getHours(shiftId, true)
    setShiftHours(shiftBasedHours)
  }

  const handleShiftHourChange = (id, key, value) => {
    const newState = shiftHours?.map((shift) => {
      if (shift.id == id) {
        shift[key] = value
      }
      return shift
    })
    setShiftHours(newState)
  }

  const renderAttendanceScheduleRange = ({
    title,
    shiftHour,
    disabled = false,
  }) => {
    return (
      <AttendanceScheduleTimeRangeInputContainer>
        <div className={"title"}>{title}</div>
        <div className={"start-range"}>
          <SelectInput
            name={"attendance_start_hour"}
            width={"65px"}
            options={CALENDAR_HOURS_MINUTES.hours}
            value={shiftHour?.start_hour}
            disabled={disabled}
            onChange={(val) =>
              handleShiftHourChange(shiftHour.id, "start_hour", val)
            }
          />
          {":"}
          <SelectInput
            name={"attendance_start_minute"}
            width={"65px"}
            options={CALENDAR_HOURS_MINUTES.minutes}
            value={shiftHour?.start_minute}
            disabled={disabled}
            onChange={(val) =>
              handleShiftHourChange(shiftHour.id, "start_minute", val)
            }
          />
        </div>
        {"~"}
        <div className={"end-range"}>
          <SelectInput
            name={"attendance_end_hour"}
            width={"65px"}
            options={CALENDAR_HOURS_MINUTES.hours}
            value={shiftHour?.end_hour}
            disabled={disabled}
            onChange={(val) =>
              handleShiftHourChange(shiftHour.id, "end_hour", val)
            }
          />
          {":"}
          <SelectInput
            name={"attendance_end_minute"}
            width={"65px"}
            options={CALENDAR_HOURS_MINUTES.minutes}
            value={shiftHour?.end_minute}
            disabled={disabled}
            onChange={(val) =>
              handleShiftHourChange(shiftHour.id, "end_minute", val)
            }
          />
        </div>
        <div className={"break-hours"}>
          {t("Break time")}
          <InputNumber
            value={shiftHour?.break_time}
            disabled={disabled}
            onChange={(val) => {
              handleShiftHourChange(shiftHour.id, "break_time", val)
            }}
            max={999}
          />
          {t("minutes")}
        </div>
      </AttendanceScheduleTimeRangeInputContainer>
    )
  }

  const handleSave = () => {
    const payload: any = {}
    payload.attendance_shift_id = selectedAttendanceShift
    shiftHours.forEach((shift) => {
      const postFix = shift?.id == 1 ? "" : shift?.id
      payload[`attendance_start_time${postFix}`] =
        selectedAttendanceShift && !isSelectedShiftVacationShift
          ? shift.start_hour + ":" + shift.start_minute
          : ""
      payload[`attendance_end_time${postFix}`] =
        selectedAttendanceShift && !isSelectedShiftVacationShift
          ? shift.end_hour + ":" + shift.end_minute
          : ""
      payload[`attendance_rest_minits${postFix}`] = shift.break_time
    })
    handleAttendanceScheduleUpdate(payload)
  }

  return (
    <Wrapper>
      <div className={"shift-select"}>
        <SelectInput
          placeholder={"-----"}
          options={shiftOptionsWithResetValue}
          name={"selected-shift"}
          value={selectedAttendanceShift}
          onChange={(val) => {
            setSelectedAttendanceShift(+val)
            updateShiftHoursOnShiftChange(+val)
          }}
        />
      </div>
      <div className={"schedule-container"}>
        {shiftHours.map((range, index) => (
          <div key={index} className={"inner-schedule-container"}>
            {renderAttendanceScheduleRange({
              title: `${t("Working hours")} ${range?.id}`,
              shiftHour: range,
              disabled:
                selectedAttendanceShift == 0 || isSelectedShiftVacationShift,
            })}
          </div>
        ))}
        <div className={"button-container"}>
          {shiftHours.length > 0 && (
            <ScheduleButton
              type={
                shiftHours.length == possibleScheduleIds.length
                  ? "remove"
                  : "add"
              }
              disabled={isSelectedShiftVacationShift}
            />
          )}
          <Button
            btnText={t("Save")}
            shape={"round"}
            style={{ background: theme.colors.link, color: theme.colors.white }}
            onClick={handleSave}
          />
        </div>
      </div>
    </Wrapper>
  )
}
