import { theme } from "@project/common"
import { Typography } from "antd"
import React from "react"
import styled from "styled-components"
const Container = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  -webkit-print-color-adjust: exact !important;
  print-color-adjust: exact !important;
  .shape_box {
    background-color: ${theme.colors.primary};
    width: fit-content;
    padding: 8px 10px;
    position: relative;
    min-height: 38px;
    height: auto;
    margin-right: 25px;
    &:after {
      position: absolute;
      top: 0px;
      right: -20px;
      content: "";
      width: 0;
      height: 0;
      border-top: 20px solid transparent;
      border-left: 20px solid ${theme.colors.primary};
      border-bottom: 18px solid transparent;
    }
  }
  @media print {
    .shape_box {
      font-size: 9pt !important;
      padding: 4px 5px !important;
      min-height: 20px;
      height: 30px !important;
      &:after {
        right: -18px;
        border-top: 16px solid transparent;
        border-left: 18px solid ${theme.colors.primary};
        border-bottom: 14px solid transparent;
      }
    }
  }
`
const TriangleBox = ({
  label,
  value,
}: {
  label: string
  value?: string | number
}): JSX.Element => {
  return (
    <Container>
      <div className={"shape_box"}>{label}</div>
      {value && <Typography.Text>{value}</Typography.Text>}
    </Container>
  )
}
TriangleBox.displayName = "TriangleBox"
export { TriangleBox }
