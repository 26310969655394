import { dynamicLangString, PrintFriendlyTable } from "@project/common"
import { notification } from "antd"
import dayjs from "dayjs"
import Link from "next/link"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useMutation, useQuery } from "react-query"
import {
  getStaffAttendanceMonthlyAttendanceWorkStyle,
  quickUpdateStaffAttendanceRecord,
} from "../../../services/staffAttendanceRecord"
import {
  getStaffAttendanceMonthlyScheduleWorkStyle,
  quickUpdateStaffAttendanceSchedule,
} from "../../../services/staffAttendanceSchedule"
import { StaffAttendanceRecordUpdatePopup } from "../StaffAttendanceRecordUpdatePopup"
import {
  IShiftInfo,
  StaffAttendanceScheduleUpdatePopup,
} from "../StaffAttendanceScheduleUpdatePopup"
import { StaffAttendanceScheduleWorkContainer } from "../StaffAttendanceScheduleWorkHourContainer"
import {
  Container,
  DayAttendanceContainer,
  DiagonalDiv,
  OccupationContainer,
  OccupationTag,
  ShiftHoursInfo,
  WorkStyleTable,
} from "./AttendanceWorkStyleTable.style"

interface IAttendanceTypeProperties {
  getDailyAttendanceLink: (val) => any
  getMonthlyStaffAttendanceLink: (val) => any
  renderDayAttendanceDuration: (val) => any
}

interface IAttendanceWorkStyleTable {
  year: string | number
  month: string | number
  facility: string | number
  type: "schedule" | "record"
  is_work_style?: boolean
  facilityShifts?: Array<any>
  hide_work_hours?: boolean
  hasEditAccess?: boolean
}

interface IRenderDayAttendanceDuration {
  display: any
  shiftInfo?: IShiftInfo
  attendanceInfo?: any
  facilityId: number
  staffId: number
  date: string
}

const AttendanceWorkStyleTable = (props: IAttendanceWorkStyleTable) => {
  const { t } = useTranslation()
  const {
    year,
    month,
    facility,
    type,
    is_work_style,
    facilityShifts = [],
    hide_work_hours = false,
    hasEditAccess = false,
  } = props

  const renderDayAttendanceDuration = (props: IRenderDayAttendanceDuration) => {
    const { display, facilityId, staffId, date, shiftInfo, attendanceInfo } =
      props
    return (
      <DayAttendanceContainer
        onClick={(e) => {
          if (!hasEditAccess) {
            e.preventDefault()
            return
          }
          closeAllOpenModals()
          const child = e.currentTarget.querySelector(
            ".attendance-popup-container",
          )
          if (child) {
            child.classList.add("show")
          }
        }}
        isDisabled={!hasEditAccess}
      >
        <div className={"attendance-popup-container"}>
          <div
            className={"close-modal"}
            onClick={(e) => {
              e.currentTarget.parentElement.classList.remove("show")
              e.stopPropagation()
            }}
          >
            <img src={"assets/icons/CloseIcon.svg"} />
          </div>
          {type == "schedule" ? (
            <StaffAttendanceScheduleUpdatePopup
              shiftOptions={facilityShifts}
              shiftInfo={shiftInfo}
              handleAttendanceScheduleUpdate={(payload) =>
                updateStaffAttendanceSchedule([
                  {
                    ...payload,
                    facility_id: facilityId,
                    staff_id: staffId,
                    date,
                  },
                ])
              }
            />
          ) : (
            <StaffAttendanceRecordUpdatePopup
              attendanceInfo={attendanceInfo}
              handleAttendanceUpdate={(payload) =>
                updateStaffAttendanceRecord([
                  {
                    ...payload,
                    facility_id: facilityId,
                    staff_id: staffId,
                    date,
                  },
                ])
              }
            />
          )}
        </div>
        {display}
      </DayAttendanceContainer>
    )
  }

  const attendanceTypeProperties: IAttendanceTypeProperties =
    type == "schedule"
      ? {
          getDailyAttendanceLink: ({ facility_id, date }) =>
            `/attendance-schedule/day-list?facility=${facility_id}&date=${date}`,
          getMonthlyStaffAttendanceLink: ({
            facility_id,
            year,
            month,
            staff_id,
          }) =>
            `/attendance-schedule/monthly-schedule?facility_id=${facility_id}&year=${year}&month=${month}&staff_id=${staff_id}`,
          renderDayAttendanceDuration,
        }
      : {
          getDailyAttendanceLink: ({ facility_id, date }) =>
            `/attendance-record/day-list?facility_id=${facility_id}&date=${date}`,
          getMonthlyStaffAttendanceLink: ({
            facility_id,
            year,
            month,
            staff_id,
          }) =>
            `/attendance-record/staff-monthly-attendance-record?facility=${facility_id}&year=${year}&month=${month}&staff_id=${staff_id}`,
          renderDayAttendanceDuration,
        }

  const daysInMonth = dayjs(year + "-" + month + "-1").daysInMonth()
  const [tableData, setTableData] = useState([])

  const renderDiagonal = () => <DiagonalDiv />

  const prepareStaffAttendanceData = (data) => {
    const dataSource = []
    const holidays = []
    // first staff data
    const staffInfos = data?.staff_infos

    staffInfos?.forEach((staff) => {
      const staffRow: any = {
        occupations: Array.isArray(staff?.occupations) ? (
          <OccupationContainer>
            {staff?.occupations?.map((occupation) => {
              if (occupation?.id) {
                return (
                  <OccupationTag
                    key={occupation?.id}
                    background={occupation?.color_code}
                  >
                    {occupation?.occupation_name}
                  </OccupationTag>
                )
              }
            })}
          </OccupationContainer>
        ) : (
          ""
        ),
        work_style: t(staff?.work_style_type),
        staff_name: hasEditAccess ? (
          <Link
            href={attendanceTypeProperties.getMonthlyStaffAttendanceLink({
              facility_id: facility,
              year,
              month,
              staff_id: staff?.staff_id,
            })}
          >
            {staff?.staff_name}
          </Link>
        ) : (
          staff?.staff_name
        ),
        month_total: staff?.monthly_total,
        average_hours_per_week: staff?.average_hours_per_week,
        qualifications: Array.isArray(staff?.staff_qualifications)
          ? staff?.staff_qualifications?.map((staffqualification, index) =>
              index == 0
                ? staffqualification?.qualification?.qualification_name
                : `, ${staffqualification?.qualification?.qualification_name}`,
            )
          : "",
        continuation_years: staff?.continuation_years
          ? staff?.continuation_years
          : "",
        number_of_people_after_full_time_conversion:
          staff?.number_of_people_after_full_time_conversion,
      }

      if (type == "schedule") {
        // day-wise schedule
        staff?.attendance_schedules?.forEach((daySchedule) => {
          const dateKey = daySchedule?.date?.toString()?.split("T")?.[0]
          let work_duration = daySchedule?.work_duration
          if (!work_duration) {
            // if no work duration
            if (
              daySchedule?.attendance_shift?.number_of_employees_include_flg
            ) {
              // if vacation schedule shift show shift name instead
              work_duration =
                daySchedule?.attendance_shift?.attendance_shift_display_name
            }
          }
          staffRow[dateKey] =
            daySchedule?.holiday_flag == "1"
              ? "休"
              : is_work_style
                ? work_duration
                : attendanceTypeProperties.renderDayAttendanceDuration({
                    display: work_duration,
                    shiftInfo: {
                      attendance_start_time: daySchedule?.attendance_start_time,
                      attendance_end_time: daySchedule?.attendance_end_time,
                      attendance_rest_minits:
                        daySchedule?.attendance_rest_minits,
                      attendance_start_time2:
                        daySchedule?.attendance_start_time2,
                      attendance_end_time2: daySchedule?.attendance_end_time2,
                      attendance_rest_minits2:
                        daySchedule?.attendance_rest_minits2,
                      attendance_shift_id: daySchedule?.attendance_shift_id,
                    },
                    facilityId: daySchedule?.facility_id,
                    staffId: daySchedule?.staff_id,
                    date: dateKey,
                  })
          // keeping track of holidays
          if (daySchedule?.holiday_flag == "1" && !holidays.includes(dateKey)) {
            holidays.push(dateKey)
          }
        })
      } else if (type == "record") {
        // day-wise record
        staff?.attendance_records?.forEach((dayRecord) => {
          const dateKey = dayRecord?.date?.toString()?.split("T")?.[0]
          let work_duration = dayRecord?.work_duration
          if (!work_duration) {
            // if no work duration
            if (dayRecord?.attendance_shift?.number_of_employees_include_flg) {
              // if vacation scheduled show holiday
              work_duration = t("休")
            }
          }
          staffRow[dateKey] =
            dayRecord?.holiday_flag == "1"
              ? "休"
              : is_work_style
                ? work_duration
                : dayRecord?.attendance_type || dayRecord?.is_scheduled
                  ? attendanceTypeProperties.renderDayAttendanceDuration({
                      display:
                        dayRecord?.attendance_type || work_duration ? (
                          work_duration
                        ) : (
                          <>&nbsp;</>
                        ),
                      attendanceInfo: {
                        rest_minits_flg: dayRecord?.rest_minits_flg,
                        rest_hours: dayRecord?.rest_hours,
                        attendance_type: dayRecord?.attendance_type,
                        attendance_start_time: dayRecord?.attendance_start_time,
                        attendance_end_time: dayRecord?.attendance_end_time,
                        attendance_rest_minits:
                          dayRecord?.attendance_rest_minits,
                        attendance_type2: dayRecord?.attendance_type2,
                        attendance_start_time2:
                          dayRecord?.attendance_start_time2,
                        attendance_end_time2: dayRecord?.attendance_end_time2,
                        attendance_rest_minits2:
                          dayRecord?.attendance_rest_minits2,
                      },
                      facilityId: dayRecord?.facility_id,
                      staffId: dayRecord?.staff_id,
                      date: dateKey,
                    })
                  : " "
          // keeping track of holidays
          if (dayRecord?.holiday_flag == "1" && !holidays.includes(dateKey)) {
            holidays.push(dateKey)
          }
        })
      }
      dataSource.push(staffRow)
    })

    // day work duration
    const dayWorkDuration: any = {
      occupations: t("Total work performance (hours)"),
      groupOccupationWorkAndNameColumns: true,
    }

    data?.day_work_duration?.forEach((dayDuration) => {
      const dateKey = dayDuration?.date?.toString()?.split("T")?.[0]
      dayWorkDuration[dateKey] = holidays?.includes(dateKey)
        ? ""
        : dayDuration?.work_duration
    })

    dataSource.push(dayWorkDuration)

    // full time employee row
    const fullTimeEmployeeRow: any = {
      occupations: t(
        "Number of hours a full-time employee should work in the office/facility in a week",
      ),
      groupOccupationWorkAndNameColumns: true,
      groupAllDates: true,
      groupMonthlyTotalAvergaeHoursAndConversionColumns: true,
      groupQualificationsAndContinuationYearColumns: true,
    }

    // value on first day
    const firstDay = dayjs(year + "-" + month + "-01").format("YYYY-MM-MM")
    fullTimeEmployeeRow[firstDay] =
      (data?.week_hours || 40)?.toString() + t("Hrs")
    dataSource.push(fullTimeEmployeeRow)

    // shift row
    const dayShiftRow: any = {
      occupations: t("Service hours"),
      groupOccupationWorkAndNameColumns: true,
      average_hours_per_week: renderDiagonal(),
      number_of_people_after_full_time_conversion: renderDiagonal(),
    }

    data?.service_hour_info?.forEach((dayServiceHour) => {
      dayShiftRow[dayServiceHour?.date] = dayServiceHour?.hours
    })

    dataSource.push(dayShiftRow)

    // number of users row
    // currently blank
    const usersRow: any = {
      occupations: t("No. of users"),
      groupOccupationWorkAndNameColumns: true,
      average_hours_per_week: renderDiagonal(),
      number_of_people_after_full_time_conversion: renderDiagonal(),
    }
    data?.day_user_info?.forEach((userInfo) => {
      const dateKey = userInfo?.date?.toString()?.split("T")?.[0]
      usersRow[dateKey] = holidays?.includes(dateKey) ? "" : userInfo?.count
    })
    dataSource.push(usersRow)

    return dataSource
  }

  const closeAllOpenModals = () => {
    document
      .querySelectorAll(".attendance-popup-container.show")
      .forEach((elem) => elem.classList.remove("show"))
  }

  const {
    data: attendanceData,
    isLoading: isAttendanceDataLoading,
    isFetching,
    refetch: refetchAttendanceData,
  } = useQuery(
    ["staff-attendance-data", facility, year, month],
    () =>
      type == "schedule"
        ? getStaffAttendanceMonthlyScheduleWorkStyle({ year, month, facility })
        : getStaffAttendanceMonthlyAttendanceWorkStyle({
            year,
            month,
            facility,
          }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      select: (response) => response?.data,
      onSuccess: (response) => {
        setTableData(prepareStaffAttendanceData(response))
      },
    },
  )

  const {
    mutate: updateStaffAttendanceSchedule,
    isLoading: isSavingAttendanceSchedule,
  } = useMutation({
    mutationFn: quickUpdateStaffAttendanceSchedule,
    onSuccess: () => {
      closeAllOpenModals()
      refetchAttendanceData()
      notification.success({
        message: dynamicLangString([
          t("Facility staff- attendance schedule"),
          t("Updated Successfully"),
        ]),
      })
    },
    onError: () => {
      notification.error({
        message: t("Something went wrong. Please contact administrator."),
      })
    },
  })

  const {
    mutate: updateStaffAttendanceRecord,
    isLoading: isSavingAttendanceRecord,
  } = useMutation({
    mutationFn: quickUpdateStaffAttendanceRecord,
    onSuccess: () => {
      closeAllOpenModals()
      refetchAttendanceData()
      notification.success({
        message: t("Facility staff-attendance record updated successfully"),
      })
    },
    onError: () => {
      notification.error({
        message: t("Something went wrong. Please contact administrator."),
      })
    },
  })

  const getColumnsForWorkStyleTable = () => {
    const columnsBeforeDates = [
      {
        title: t("Occupation"),
        key: "occupation",
        dataIndex: "occupations",
        align: "center",
        className: "occupation-column",
        onCell: (obj) => {
          if (obj?.groupOccupationWorkAndNameColumns)
            return {
              colSpan: 3,
            }
          return {}
        },
      },
      {
        title: t("Work style"),
        key: "work_style",
        dataIndex: "work_style",
        align: "center",
        className: "work-style-column",
        onCell: (obj) => {
          if (obj?.groupOccupationWorkAndNameColumns)
            return {
              colSpan: 0,
            }
          return {}
        },
      },
      {
        title: t("Name"),
        key: "name",
        dataIndex: "staff_name",
        className: "staff-name-column",
        align: "center",
        onCell: (obj) => {
          if (obj?.groupOccupationWorkAndNameColumns)
            return {
              colSpan: 0,
            }
          return {}
        },
      },
    ]
    const columnsAfterDates = [
      {
        title: t("Month total"),
        key: "month_total",
        dataIndex: "month_total",
        className: "month-total-column",
        align: "center",
        onCell: (obj) => {
          if (obj?.groupMonthlyTotalAvergaeHoursAndConversionColumns) {
            return {
              colSpan: 3,
            }
          }
          return {}
        },
      },
      {
        title: t("Average working hours per week"),
        key: "average_hours_per_week",
        dataIndex: "average_hours_per_week",
        align: "center",
        className: "average-hours-column",
        onCell: (obj) => {
          if (obj?.groupMonthlyTotalAvergaeHoursAndConversionColumns) {
            return {
              colSpan: 0,
            }
          }
          return {}
        },
      },
      {
        title: t("Number of people after full-time conversion"),
        key: "number_of_people_after_full_time_conversion",
        dataIndex: "number_of_people_after_full_time_conversion",
        align: "center",
        className: "people-conversion-column",
        onCell: (obj) => {
          if (obj?.groupMonthlyTotalAvergaeHoursAndConversionColumns) {
            return {
              colSpan: 0,
            }
          }
          return {}
        },
      },
      {
        title: t("Presence or absence of qualifications and kinds"),
        key: "qualifications",
        dataIndex: "qualifications",
        align: "center",
        className: "qualifications-column",
        onCell: (obj) => {
          if (obj?.groupQualificationsAndContinuationYearColumns) {
            return {
              colSpan: 2,
            }
          }
          return {}
        },
      },
      {
        title: t("Continuation work number of years"),
        key: "continuation_years",
        dataIndex: "continuation_years",
        align: "center",
        className: "continuation-year-column",
        onCell: (obj) => {
          if (obj?.groupQualificationsAndContinuationYearColumns) {
            return {
              colSpan: 0,
            }
          }
          return {}
        },
      },
    ]
    const columnsForWeek = []

    const firstDay = dayjs(year + "-" + month + "-1")

    const getFirstDayOfWeek = () => {
      const fDay = firstDay.day()
      // because starting from monday
      if (fDay == 0) return 6
      return fDay - 1
    }

    const howManyDaysOnFirstWeek = () => {
      return 7 - getFirstDayOfWeek()
    }

    const getDay = (day: number) => {
      switch (day) {
        case 0:
          return t("Mon")
        case 1:
          return t("Tue")
        case 2:
          return t("Wed")
        case 3:
          return t("Thu")
        case 4:
          return t("Fri")
        case 5:
          return t("Sat")
        case 6:
          return t("Sun")
        default:
          return ""
      }
    }

    const maxWeeks = Math.ceil((daysInMonth - howManyDaysOnFirstWeek()) / 7 + 1)
    const numberOfWeeks = Array.from({ length: maxWeeks }, (_, i) => i + 1)
    let daysAccounted = 0
    numberOfWeeks.forEach((weekNo) => {
      const currentDayOfWeek = weekNo == 1 ? getFirstDayOfWeek() : 0
      const weekColumn = {
        title: t("Week{{weekNo}}", { weekNo }),
        className: "week-header",
        children: [],
      }
      for (let dayOfWeek = currentDayOfWeek; dayOfWeek < 7; dayOfWeek++) {
        if (daysAccounted >= daysInMonth) break
        daysAccounted++
        const thisDate = dayjs(year + "-" + month + "-" + daysAccounted).format(
          "YYYY-MM-DD",
        )
        const weekDayColumn = {
          title: is_work_style ? (
            daysAccounted
          ) : hasEditAccess ? (
            <Link
              href={attendanceTypeProperties?.getDailyAttendanceLink({
                facility_id: facility,
                date: thisDate,
              })}
            >
              {daysAccounted}
            </Link>
          ) : (
            daysAccounted
          ),
          align: "center",
          className: is_work_style ? "each-date-work-style" : "each-date",
          children: [
            {
              title: getDay(dayOfWeek),
              align: "center",
              dataIndex: thisDate,
              className: "each-date-day",
              onCell: (obj) => {
                if (obj?.groupAllDates) {
                  return {
                    colSpan: firstDay?.isSame(thisDate) ? daysInMonth : 0,
                  }
                }
                return {}
              },
            },
          ],
        }
        weekColumn.children.push(weekDayColumn)
      }
      columnsForWeek.push(weekColumn)
    })

    return []
      .concat(columnsBeforeDates)
      .concat(columnsForWeek)
      .concat(columnsAfterDates)
  }

  const renderShiftInfo = () => {
    const shiftData = attendanceData?.used_schedules || []
    return shiftData
      ?.map((shift, index) => {
        let shiftString = ""
        if (index == 0) {
          shiftString += "※"
        }
        shiftString +=
          shift?.attendance_shift_display_name +
          " = " +
          shift?.attendance_start_time +
          "〜" +
          shift?.attendance_end_time
        if (shift?.attendance_start_time_2 && shift?.attendance_end_time_2) {
          shiftString +=
            ", " +
            shift?.attendance_start_time_2 +
            "〜" +
            shift?.attendance_end_time_2
        }
        return shiftString
      })
      ?.join(",")
  }

  return (
    <Container>
      <div>
        <PrintFriendlyTable
          columns={getColumnsForWorkStyleTable()}
          dataSource={tableData}
          padding={"0.1rem"}
          fontSize={"6.7pt"}
        />
      </div>

      <WorkStyleTable
        columns={getColumnsForWorkStyleTable()}
        bordered={true}
        loading={
          isAttendanceDataLoading ||
          isFetching ||
          isSavingAttendanceSchedule ||
          isSavingAttendanceRecord
        }
        dataSource={tableData}
        pagination={false}
      />
      <ShiftHoursInfo>{renderShiftInfo()}</ShiftHoursInfo>
      {!hide_work_hours && (
        <StaffAttendanceScheduleWorkContainer
          daySetting={
            attendanceData?.day_hours &&
            !isNaN(attendanceData?.day_hours) &&
            !isNaN(attendanceData?.day_minutes)
              ? `${attendanceData?.day_hours}時間${(
                  attendanceData?.day_minutes || 0
                )
                  ?.toString()
                  ?.padStart(2, "0")}分`
              : "No Setting"
          }
          weekSetting={
            attendanceData?.week_hours &&
            !isNaN(attendanceData?.week_hours) &&
            !isNaN(attendanceData?.week_minutes)
              ? `${attendanceData?.week_hours}時間${(
                  attendanceData?.week_minutes || 0
                )
                  ?.toString()
                  ?.padStart(2, "0")}分`
              : "No Setting"
          }
        />
      )}
    </Container>
  )
}

export { AttendanceWorkStyleTable }
