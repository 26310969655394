import React from "react"
import { Button } from "@project/common"
import { t } from "i18next"

// Styles for the buttons
const EmploymentTypeBButton = () => (
  <Button
    btnText={t("Employment Type B")}
    style={{
      backgroundColor: "#f2fff7",
      color: "#4cb476",
      borderColor: "#4cb476",
    }}
  />
)

const EmploymentTypeAButton = () => (
  <Button
    btnText={t("Employment Type A")}
    style={{
      backgroundColor: "#fffdf3",
      color: "#cda001",
      borderColor: "#cda001",
    }}
  />
)

const EmploymentTransitionSupportButton = () => (
  <Button
    btnText={t("Employment Transition Support")}
    style={{
      backgroundColor: "#eff9ff",
      color: "#0782c8",
    }}
  />
)

export const UseServiceType = ({ type }: { type: number }): JSX.Element => {
  switch (type) {
    case 2:
      return <EmploymentTypeBButton />
    case 1:
      return <EmploymentTypeAButton />
    case 3:
      return <EmploymentTransitionSupportButton />
    default:
      return null
  }
}
