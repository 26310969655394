import { DownOutlined, UpOutlined } from "@ant-design/icons"
import styled from "styled-components"
const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  svg {
    font-size: 16px;
    height: 10px;
    font-weight: bolder;
  }
`
export const SortIcon = ({
  ascendingColor,
  descendingColor,
}: {
  color?: string
  ascendingColor?: string
  descendingColor?: string
}): JSX.Element => {
  return (
    <StyledWrapper>
      <UpOutlined
        style={{
          color: ascendingColor,
        }}
      />
      <DownOutlined
        style={{
          color: descendingColor,
        }}
      />
    </StyledWrapper>
  )
}
