import {
  Button,
  DatePicker,
  LabelTextWithLeftArrow,
  OperationOptionContainer,
  SelectInput,
} from "@project/common"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import dayjs from "dayjs"

interface IAttendanceScheduleOperationOption {
  facilityOptions: [any]
  initialState: {
    facility_id: number
    year: string | number
    month: string | number
  }
  handleDisplayChange: (val) => void
}

const AttendanceScheduleOperationOption = (
  props: IAttendanceScheduleOperationOption,
) => {
  const { t } = useTranslation()
  const { facilityOptions, initialState, handleDisplayChange } = props
  const [searchOptions, setSearchOptions] = useState(initialState)
  useEffect(() => {
    setSearchOptions(initialState)
  }, [initialState])
  return (
    <OperationOptionContainer>
      <div className={"search__content"}>
        <LabelTextWithLeftArrow label={t("Facility")} width={"138px"} />
        <SelectInput
          name={"facility"}
          options={facilityOptions?.map((facility) => {
            return {
              label: facility?.facility_name,
              value: facility?.id,
            }
          })}
          value={+searchOptions?.facility_id}
          onChange={(val) => {
            setSearchOptions((prev) => {
              return { ...prev, facility_id: val }
            })
          }}
        />
      </div>
      <div className={"search__content"}>
        <LabelTextWithLeftArrow label={t("Year Month")} width={"138px"} />
        <DatePicker
          date={dayjs(searchOptions.year + "-" + searchOptions.month + "-1")}
          onDateChange={(date) => {
            setSearchOptions((prev) => {
              return {
                ...prev,
                year: dayjs(date).format("YYYY"),
                month: dayjs(date).format("MM"),
              }
            })
          }}
          format={"YYYY年MM月"}
          picker={"month"}
        />
      </div>
      <div className={"search__content btns"}>
        <Button
          btnText={t("Display Change")}
          type={"default"}
          size={"large"}
          shape={"round"}
          onClick={() => {
            handleDisplayChange(searchOptions)
          }}
        />
      </div>
    </OperationOptionContainer>
  )
}

export { AttendanceScheduleOperationOption }
