import { API } from "@project/common"

const BASE_ATTENDANCE_RECORD_URL = "/staff-attendance"

interface IStaffAttendanceMonthlAttendanceWorkStyle {
  facility: string | number
  year: string | number
  month: string | number
}

export const getStaffAttendanceMonthlyAttendanceWorkStyle = ({
  facility,
  year,
  month,
}: IStaffAttendanceMonthlAttendanceWorkStyle) =>
  API.get(
    `${BASE_ATTENDANCE_RECORD_URL}/work-style?facility=${facility}&year=${year}&month=${month}`,
  )

export const quickUpdateStaffAttendanceRecord = (payload) =>
  API.post(BASE_ATTENDANCE_RECORD_URL, payload)

export const createStaffAttendance = async (values): Promise<any> => {
  delete values?.id
  return API.post(`/${BASE_ATTENDANCE_RECORD_URL}`, values)
}
export const updateStaffAttendance = async (values) => {
  const id = values.id
  delete values.id
  return API.put(`/${BASE_ATTENDANCE_RECORD_URL}/${id}`, values)
}
export const getStaffAttendance = async ({ queryKey }) => {
  const id = queryKey[1]
  return API.get(`/${BASE_ATTENDANCE_RECORD_URL}/${id}`)
}
