import { theme } from "@project/common"
import { styled } from "styled-components"

const OccupationContainer = styled.div`
  @media print {
    display: none;
  }
  border: 1px solid #d2d1d1;
  border-radius: 12px;
  padding: 20px;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  .occupation-row {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
    gap: 10px;
    .occupation-label-value-container {
      display: flex;
      align-items: center;
      margin-right: 16px;
      .occupation-label {
        margin-right: 4px;
        font-size: 14px;
        color: #ffffff;
        padding: 6px 10px;
        border-radius: 5px;
        text-align: center;
        min-width: 90px;
      }
      .occupation-value {
        font-size: 14px;
      }
    }
  }
  .occupation-row:last-child {
    margin-bottom: 0;
  }
`

interface IOccupationList {
  occupations: Array<any>
}

const StaffOccupationList = (props: IOccupationList) => {
  const { occupations } = props
  return (
    <OccupationContainer>
      {occupations?.map((occupation, index) => (
        <div className={"occupation-row"} key={index}>
          <div
            className={"occupation-label-value-container"}
            key={occupation.id}
          >
            <span
              className={"occupation-label"}
              style={{
                background: occupation?.color_code || theme.colors.error,
              }}
            >
              {occupation?.occupation_name}
            </span>
            <span className={"occupation-value"}>
              {occupation?.occupation_name}
            </span>
          </div>
        </div>
      ))}
    </OccupationContainer>
  )
}

export { StaffOccupationList }
