import { useRouter } from "next/router"
import { useContext, useEffect } from "react"

import { InitialLoader, Layout, auth } from "@project/common"
import { notification } from "antd"
import { signOut } from "firebase/auth"
import { useTranslation } from "react-i18next"
import { AuthContext } from "../context/AuthContext"
import { PageObj } from "../utils/pageAccessUtils"
import {
  futureReleaseRoutes,
  hasPermissionForMenu,
  menuItems,
} from "../utils/sidebarUtils"

const WithPrivateRoute = (AuthComponent) => {
  return function PrivateComponent(props) {
    const router = useRouter()
    const pathname = router.pathname
    const { t } = useTranslation()
    const {
      authenticated,
      loading,
      user,
      isOwner,
      companyName,
      permissions,
      sideBarProps,
      sideBarToggleProps,
    } = useContext(AuthContext)
    const rogueRoutes = futureReleaseRoutes.map((route) => route.route)

    const logOut = () => {
      signOut(auth)
      router.replace("/login")
      window.location.href = "/login"
    }

    useEffect(() => {
      if (!loading) {
        if (!authenticated) {
          router.replace(
            {
              pathname: "/login",
              query: {
                ...router.query,
                from: router?.pathname,
              },
            },
            "/login",
          )
          return
        }
        if (authenticated && router?.pathname === "/") {
          if (user?.reloadUserInfo?.customAttributes) {
            const customAttributes = JSON.parse(
              user?.reloadUserInfo?.customAttributes,
            )
            const companyId = customAttributes.companyId || 0
            localStorage.setItem("companyId", companyId)
          }
          router.replace("/")
        }
        router.push(
          {
            pathname: router?.pathname,
            query: {
              ...router?.query,
            },
          },
          router?.asPath || router?.pathname,
        )
      }
    }, [authenticated, loading, user])

    if (loading || (!isOwner && !permissions)) {
      return (
        <InitialLoader
          logoImg={
            <img src={"/assets/logo.svg"} height={60} width={60} alt={"logo"} />
          }
        />
      )
    }
    // this will be removed once we have all features available
    if (rogueRoutes.includes(router.asPath)) {
      router.replace("/feature-not-available")
      return null
    }

    if (pathname !== "/") {
      if (!isOwner) {
        const currentPageObject = PageObj.find(
          (page) => page.routeLike == pathname,
        )

        if (currentPageObject) {
          if (
            !currentPageObject?.skipPermissions &&
            !hasPermissionForMenu(
              permissions,
              currentPageObject?.permissionKeys,
              currentPageObject?.premissionType,
            )
          ) {
            notification.error({
              message: t("User doesn't have permission to view this page"),
              key: "permission-error-tost",
            })
            router.replace("/")
            return null
          }
        }
      }
    }

    // based on assumption that there's single expansion for menu
    const filterMenuByPermission = () => {
      if (isOwner) return menuItems
      return menuItems
        .filter((item) => {
          if (!item.requiresPermission || !item?.permissionKeys) {
            return true
          }
          if (Array.isArray(item.permissionKeys)) {
            return hasPermissionForMenu(permissions, item.permissionKeys)
          }
          return true
        })
        .map((menu) => {
          if (isOwner || !menu.requiresPermission) return menu
          if (Array.isArray(menu?.children)) {
            const accessibltChildMenu = menu.children.filter((childMenu) => {
              if (Array.isArray(childMenu.permissionKeys))
                return hasPermissionForMenu(
                  permissions,
                  childMenu.permissionKeys,
                )
              return true
            })
            menu.children = accessibltChildMenu
          }

          return menu
        })
    }

    return (
      <>
        {authenticated ? (
          <main>
            <Layout
              menuItems={filterMenuByPermission()}
              logOut={logOut}
              headerInfo={{
                isOwner: isOwner,
                companyName,
              }}
              sideBarProps={sideBarProps}
              sideBarToggleProps={sideBarToggleProps}
            >
              <AuthComponent {...props} />
            </Layout>
          </main>
        ) : null}
      </>
    )
  }
}

export default WithPrivateRoute
