import { theme } from "@project/common"
import { useTranslation } from "react-i18next"
import { styled } from "styled-components"

const Container = styled.div`
  @media print {
    display: none;
  }
  padding: 20px;
  margin-bottom: 20px;
  border: ${theme.border.default};
  border-radius: 12px;
  display: inline-flex;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 10px;
  .time-label-value {
    display: flex;
    align-items: center;
    gap: 10px;
    .time-label {
      position: relative;
      background: ${theme.colors.primary};
      padding: 8px 0 8px 8px;
      height: 40px;
      margin-right: 18px;
    }
    .time-label::after {
      width: 15px;
      position: absolute;
      top: 0;
      right: -35px;
      content: "";
      border-left: 20px solid ${theme.colors.primary};
      border-top: 20px solid transparent;
      border-bottom: 20px solid transparent;
    }
    @media screen and (max-width: 426px) {
      gap: 0;

      > div:last-child {
        margin-left: 10px;
      }
    }
  }
  @media (max-width: ${theme.breakpoints.md}) {
    padding: 10px;
    .time-value {
      font-size: 14px;
    }
    .time-label {
      font-size: 12px;
      margin-right: 14px;
    }
  }
  @media screen and (max-width: 426px) {
    gap: 0;

    > div:last-child {
      margin-left: 10px;
    }
  }
`

interface IStaffAttendanceScheduleWorkContainer {
  daySetting?: string
  weekSetting?: string
}

const StaffAttendanceScheduleWorkContainer = (
  props: IStaffAttendanceScheduleWorkContainer,
) => {
  const { t } = useTranslation()
  const { daySetting = "No Setting", weekSetting = "No Setting" } = props
  return (
    <Container>
      <div className={"time-label-value"}>
        <div className={"time-label"}>{t("1 day")}</div>
        <div className={"time-value"}>{t(daySetting)}</div>
      </div>
      <div className={"time-label-value"}>
        <div className={"time-label"}>{t("1 week")}</div>
        <div className={"time-value"}>{t(weekSetting)}</div>
      </div>
    </Container>
  )
}

export { StaffAttendanceScheduleWorkContainer }
