import React from "react"
import Head from "next/head"
import { useTranslation } from "react-i18next"

const PageHead = ({ children }: { children: any }) => {
  const { t } = useTranslation()
  return (
    <Head>
      <title>
        {children}
        {" | "}
        {t("Employment support system")}
      </title>
    </Head>
  )
}

PageHead.displayName = "PageHead"
export { PageHead }
