import { Box, Button, SelectInput } from "@project/common"
import { styled } from "styled-components"
import { FuriganaAlphabetsOption } from "../../../utils/common-options"
import { useEffect, useState } from "react"
import { useRouter } from "next/router"
import { useTranslation } from "react-i18next"

const StaffAttendanceMonthEditButtonsWrapper = styled.div`
  @media print {
    display: none;
  }
  margin: 15px 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  .first-wrapper {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    .select-container {
      display: flex;
      gap: 10px;
      align-items: center;
      flex-wrap: wrap;
    }
  }
  .print-wrapper {
    display: block;
  }
`

interface IStaffMonthlyEditContainer {
  staffList: Array<any>
  editButtontitle: string
  editLink: string
  editFacilityId: string | number
  editYear: string | number
  editMonth: string | number
  hasEditAccess?: boolean
}

const StaffMonthlyEditContainer = (props: IStaffMonthlyEditContainer) => {
  const {
    staffList,
    editButtontitle,
    editLink,
    editFacilityId,
    editYear,
    editMonth,
    hasEditAccess = false,
  } = props
  const { t } = useTranslation()
  const router = useRouter()
  const [staffSelection, setStaffSelection] = useState({
    furigana_option: null,
    staff_id: null,
    staff_options: staffList || [],
  })

  useEffect(() => {
    setStaffSelection((prev) => ({ ...prev, staff_options: staffList }))
  }, [staffList])

  useEffect(() => {
    const hasStaff = staffSelection.staff_options?.find(
      (staff) => staff?.value == staffSelection?.staff_id,
    )
    if (!hasStaff) {
      setStaffSelection((prev) => ({ ...prev, staff_id: null }))
    }
  }, [staffSelection.staff_options])

  return (
    <StaffAttendanceMonthEditButtonsWrapper>
      <div className={"first-wrapper"}>
        <div className={"select-container"}>
          <Box maw={75} miw={75}>
            <SelectInput
              name={"furigana"}
              width={"75px"}
              options={FuriganaAlphabetsOption}
              value={staffSelection.furigana_option}
              onChange={(val) => {
                const newState = {
                  ...staffSelection,
                  furigana_option: val,
                }
                if (val) {
                  newState.staff_options = staffList?.filter(
                    (staff) => staff?.furigana?.startsWith(val),
                  )
                } else {
                  newState.staff_options = staffList
                }
                setStaffSelection(newState)
              }}
            />
          </Box>

          <Box maw={200} miw={200}>
            <SelectInput
              width={"200px"}
              name={"staff"}
              value={staffSelection.staff_id}
              options={staffSelection.staff_options}
              onChange={(val) => {
                setStaffSelection((prev) => ({
                  ...prev,
                  staff_id: val,
                }))
              }}
            />
          </Box>

          {"さんの"}
        </div>
        <Button
          type={"default"}
          shape={"round"}
          btnText={editButtontitle}
          disabled={!hasEditAccess || !staffSelection.staff_id}
          onClick={() => {
            router.push(
              `${editLink}?facility_id=${editFacilityId}&year=${editYear}&month=${editMonth}&staff_id=${staffSelection?.staff_id}`,
            )
          }}
        />
      </div>
      <div className={"second-wrapper"}>
        <Button
          type={"default"}
          shape={"round"}
          btnText={t("Print")}
          iconType={"print-disable"}
          onClick={() => window.print()}
        />
      </div>
    </StaffAttendanceMonthEditButtonsWrapper>
  )
}

export { StaffMonthlyEditContainer }
