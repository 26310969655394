import React from "react"
import { Grid, theme } from "@project/common"
import { Collapse, Flex, Typography } from "antd"
import styled from "styled-components"
import { Option } from "@project/common/src/types"
import { useTranslation } from "react-i18next"
const Wrapper = styled.div<any>`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  border: 1px solid ${theme.colors.error};
  border-radius: 12px;
  padding: 16px;
  .info__container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: ${(props) => props?.$width || "40%"};
    .title_info {
      display: flex;
      gap: 5px;
      h5 {
        font-weight: bold;
        font-size: 16px;
        color: ${(props) => props?.$color || theme.colors.error};
        margin: 0;
      }
    }
    .sub__info {
      font-weight: bold;
      color: ${(props) => props?.$color || theme.colors.error};
    }
  }
  .daily-list-info {
    .ant-collapse-header {
      padding: 0 !important;
      .ant-collapse-expand-icon {
        color: red;
      }
    }
    .ant-collapse-content-box {
      padding: 4px 0 !important;
    }
  }
  @media screen and (max-width: ${theme.breakpoints.md}) {
    .info__container {
      width: 100%;
    }
  }
  @media screen and (max-width: ${theme.breakpoints.xl}) {
    .info__container {
      width: 70%;
    }
  }
`

interface AlertBoxProps {
  heading: string
  subHeading?: any
  expandHeading?: string
  expandText?: string
  data?: Option[]
  isCollapseAble?: boolean
  children?: React.ReactNode
  width?: string
  color?: string
  isArray?: boolean
}
export const AlertBox = ({
  heading,
  subHeading,
  expandHeading,
  data,
  isCollapseAble = true,
  children,
  width,
  color,
  isArray,
}: AlertBoxProps): JSX.Element => {
  const { t } = useTranslation()
  return (
    <Wrapper $width={width} $color={color || theme.colors.error}>
      <div className={"info__container"}>
        <div className={"title_info"}>
          <img src={"/assets/icons/NotificationAlert.svg"} alt={"icon"} />
          <Typography.Title level={5}>{heading}</Typography.Title>
        </div>
        {subHeading && (
          <div className={"sub__info"}>
            {isArray
              ? subHeading.map((item, index) => (
                  <span key={index} style={{ marginRight: "10px" }}>
                    {t(item)}
                  </span>
                ))
              : subHeading}
          </div>
        )}
      </div>
      {isCollapseAble && (
        <Collapse defaultActiveKey={["1"]} ghost className={"daily-list-info"}>
          <Collapse.Panel
            header={
              <span style={{ color: theme.colors.error }}>{expandHeading}</span>
            }
            key={"1"}
          >
            {data?.map((val, index) => (
              <Grid
                labelContent={val?.label}
                background
                padding={"10px"}
                key={index}
              >
                <Flex gap={8} wrap={"wrap"}>
                  <Typography.Text>{val?.value}</Typography.Text>
                </Flex>
              </Grid>
            ))}
          </Collapse.Panel>
        </Collapse>
      )}
      {children && children}
    </Wrapper>
  )
}
AlertBox.Displayname = "AlertBox"
