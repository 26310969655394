import { FACILITY_TYPES, theme } from "@project/common"
import { Typography } from "antd"
import dayjs, { Dayjs } from "dayjs"
import React from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
const Wrapper = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  margin-bottom: 8px !important;
  @media screen and (max-width: ${theme.breakpoints?.sm}) {
    align-items: flex-start;
  }
`
//f-facility name,U-use service, C-created date
export const FUCInfo = ({
  facility_name,
  use_service_id,
  created_datetime,
}: {
  facility_name: string | undefined | null
  use_service_id: number
  created_datetime: Dayjs
}): JSX.Element => {
  const { t } = useTranslation()
  return (
    <Wrapper className={"text__info"}>
      <div>
        <Typography.Text strong>
          {t("Facility name")} {":"}
        </Typography.Text>
        <Typography.Text>{facility_name}</Typography.Text>
      </div>
      <div>
        <Typography.Text strong>
          {t("Use service")} {":"}
        </Typography.Text>
        <Typography.Text>
          {t(
            FACILITY_TYPES?.find((type) => type?.value === use_service_id)
              ?.label,
          )}
        </Typography.Text>
      </div>
      <div>
        <Typography.Text strong>
          {t("Created date")} {":"}
        </Typography.Text>
        <Typography.Text>
          {created_datetime
            ? dayjs(created_datetime).format("YYYY年MM月DD日")
            : dayjs().format("YYYY年MM月DD日")}
        </Typography.Text>
      </div>
    </Wrapper>
  )
}
